$(document).ready(function () {
  window.allCountriesStatesCities = {};

  const $codeDetailsPendingRejectedTmp = $('#codeDetailsPendingRejectedTemplate');
  const $codeDetailPendingRejectedList = $('.js-code-details-pending-rejected-list');
  const $contentDetailsApprove = $('.js-content-detail-approved ');
  const $iataAgent = $contentDetailsApprove.find('.js-iata-agent');
  const $inputAgentDetailCountry = $contentDetailsApprove.find('.js-agent-details-country');
  const $inputContryLegalName = $contentDetailsApprove.find('.js-agent-details-legal');
  const $inputTicket = $('input[name="iataTicket"]');
  const $containerTicketsIssued = $('.js-container-tickets-issued');
  const $ticketsTidsAdd = $('.js-tickets-tids-add');
  const $ticketTidsListContainer = $('.js-iata-tids-ticket-list');
  const $ticketsTidsAddText = $('.js-tickets-tids-add-text');
  const $postFormDetailApproved = $('form[name="formCodeDetailsApproved"]');
  const $agencyTitleBox = $('.js-agency-title-box');
  const $makePrimaryContainer = $('.js-make-primary-code-container');
  const $organisationDetail = $('.js-your-organisation-detail');
  const $modalPrimaryButtonAction = $('.js-modal-confirm-primary');
  const $backLink = $('.js-back-link');
  const $tabDetail = $('.js-tab-profile');
  const $agecyTypeList = $postFormDetailApproved.find('.js-agency-type-list');
  const $submitCodeDetailApproved =  $('.js-submit-code-detail-approved');

  let $tabActive;

  const approved = 'approved';
  const pending = 'pending';
  const rejected = 'rejected';

  const iataTidsNumber = '.js-iata-tids-number';

  // Item List states
  let currentTicketIataItemIndex = 0;
  let $dropDownState;
  let $dropDownCity;

  const $iataTidsItemTemplate = $('#iataTidsItemTemplate');

  const requestingCode = window.getParameterByName('code');

  const pageModel = {
    $consolidatorRemovingItem: null,
    codeDetails: null,
    unsaved: false,
  };

  if (!requestingCode) {
    return;
  }

  loadDataCodeDetails();

  $(document).on('change', '.js-code-detail-field', function () {
    pageModel.unsaved = true;
  });

  function loadDataCodeDetails() {
    window.sia.APICaller.getCountriesStatesCities().then(function(data) {
      window.allCountriesStatesCities = data;

      return window.sia.APICaller.getCodeDetails({
        code: requestingCode,
      });
    }).then(function(data) {
      pageModel.codeDetails = data;

      let checkDataPopulate = data.registrationStatus.toLocaleLowerCase();

      if (checkDataPopulate === approved) {
        populateDetailsApproved(data);
      } else if (checkDataPopulate === rejected || checkDataPopulate === pending) {
        populateDetailsPendingRejected(data);
        $('form[name="formCodeDetailsApproved"]').addClass('hidden');
        $('.js-alert-code-detail').removeClass('hidden');
      }


      $('.js-alert-close').on('click', alertCloseHandler);

      if (data.isPrimary === false) {
        $makePrimaryContainer.show();
      }
    });
  }

  function alertCloseHandler() {
    $(this).alert('close');
  }

  function populateDetailsPendingRejected(data) {
    const codeDetailsPendingRejectedList = Handlebars.compile($codeDetailsPendingRejectedTmp.html());
    const htmlCodeDetailsPendingRejected = codeDetailsPendingRejectedList({ data: data });
    $codeDetailPendingRejectedList.html(htmlCodeDetailsPendingRejected);
  }

  function populateDetailsApproved(data) {
    populateOrganisationDetail(data);
    populateBusinessAgency(data);
    window.checkMaxContainer();
  }

  function populateOrganisationDetail(data) {
    const country = getCountryFromCountryCode(data.countryCode);
    const state = getStateFromStateCode(data.stateCode);
    const city = getCityFromCityCode(data.cityCode);

    $('.js-iata-agent').parent().find('.js-float-label').text(data.codeType);

    $agencyTitleBox.text(getCodeName(data.codeType, data.code, data.isPrimary, data.legalName));

    const states = window.allCountriesStatesCities.states.filter(state => state.countryCode === data.countryCode);
    const cities = window.allCountriesStatesCities.cities.filter(city => city.countryCode === data.countryCode);

    populateCodeDetailStates(states, state);
    populateCodeDetailCities(cities, city);

    if (data.consolidators.length) {
      $('input[value=use_a_consolidator_or_ticket_center]').attr('checked', true);
      $containerTicketsIssued.removeClass('hidden');
      populateTicketIssued(data);
    }

    window.updateDataField($iataAgent, data.code);
    window.updateDataField($inputAgentDetailCountry, country.countryName);
    $inputAgentDetailCountry.attr('data-value', country.countryCode);
    window.updateDataField($inputContryLegalName, data.legalName);
  }

  function populateCodeDetailStates(states, defaultValue = {}) {
    states = states || [];

    let dataTransform = [];
    states.forEach(function (state) {
      dataTransform.push({
        value: state.stateCode,
        text: state.stateName,
        related: state.stateCode,
      });
    });

    $dropDownState = window.populateDropdownEnhanced({
      data: dataTransform,
      defaultValue: {
        value: defaultValue.stateCode,
        text: defaultValue.stateName,
      },
      $component: $('.js-code-detail-state-dropdown'),
      $templateContainer: $('#codeDetailStateTemplate'),
    });

    $dropDownState.on('selectedItem.drpAutoComplete', handleSelectedState);
  }

  function populateCodeDetailCities(cities, defaultValue = {}) {
    cities = cities || [];

    let dataTransform = [];
    cities.forEach(function (city) {
      dataTransform.push({
        value: city.cityCode,
        text: city.cityName,
        related: city.stateCode,
      });
    });

    $dropDownCity = window.populateDropdownEnhanced({
      data: dataTransform,
      defaultValue: {
        value: defaultValue.cityCode,
        text: defaultValue.cityName,
      },
      $component: $('.js-code-detail-city-dropdown'),
      $templateContainer: $('#codeDetailCityTemplate'),
    });
  }

  function populateBusinessAgency(data) {
    const $labelAgencyBusiness = $('.js-agency-type-list').find('.js-handle-keypress-to-select');
    let natureBusinesses = data.agencyTypes.filter(function(agency) {
      return agency.agencyTypeCode;
    });

    $labelAgencyBusiness.each(function (index, elem) {
      const $elem = $(elem);
      const textAgencyBusiness = $elem.find('.js-checkbox-handler-agency').val().toLocaleLowerCase().trim();

      let i;
      for (i = 0; i < natureBusinesses.length; i++) {
        let textNatureBusiness = natureBusinesses[i].agencyTypeCode.toLocaleLowerCase().trim();

        if (textAgencyBusiness == textNatureBusiness) {
          $elem.find('.js-checkbox-handler-agency').attr('checked', true);
        }
      }
    });
  }

  function handleSelectedState(e, target) {
    const stateCode = $(target).data('related');

    const cities = window.allCountriesStatesCities.cities.filter(city => city.stateCode === stateCode);

    reset($dropDownCity);
    populateCodeDetailCities(cities);
  }

  function reset($drp) {
    const isDisabled = !$drp.find('ul > li.js-drp-item').length;

    $drp.find('input[type="text"]')
      .val('').setCustomValidity('')
      .trigger('resetvalidation')
      .prop('disabled', isDisabled);

    $drp.find('.js-float-label').removeClass('floating-label-modify');
  }

  function populateTicketIssued(data) {
    for (var i = 0; i < data.consolidators.length; i++) {
      renderTicketIssued(data.consolidators[i]);
    }
  }

  function resetConsolidatorIndexes() {
    // Reset ticket index
    const $ticketIndex = $('.js-ticket-index');

    const numberTicket = $ticketIndex.length;

    $ticketIndex.each(function (index, item) {
      if (index === 0 && numberTicket === 1) {
        $(item).parents('.js-iata-tids-number').find('.js-remove-item').hide();
      }

      $(item).html(index + 1);
    });

    return numberTicket;
  }

  function removeConsolidator($removedElement) {
    $removedElement.remove();
    // Reset currentTicketIataItemIndex
    currentTicketIataItemIndex = resetConsolidatorIndexes();

    const iataCodeCount = $ticketTidsListContainer.find(iataTidsNumber).length;

    if (iataCodeCount === 0) {
      $ticketsTidsAddText.html('Add IATA code');
    }

    window.validateInvalidDuplicatedFields();
  }

  function renderTicketIssued(data) {
    const iataTidsItemTpl = Handlebars.compile($iataTidsItemTemplate.html());
    const $renderedElement = renderAppendMode(iataTidsItemTpl, $ticketTidsListContainer, {
      isIataagentType: true,
      hideAgentTypeSelection: true,
      elmIndex: currentTicketIataItemIndex,
      indexTitle: currentTicketIataItemIndex + 1,
      forTicket: true
    });

    resetConsolidatorIndexes();

    $containerTicketsIssued.find('.js-remove-item').show();
    $containerTicketsIssued.find('.js-remove-text').text('Remove');

    const $removeButton = $renderedElement.find('.js-remove-item');
    $removeButton.on('click', function () {
      const $this = $(this);
      const $containerIata = $this.closest(iataTidsNumber);
      pageModel.$consolidatorRemovingItem = $containerIata;
      removeConsolidator(pageModel.$consolidatorRemovingItem);
      pageModel.$consolidatorRemovingItem = null;
    });

    window.initializeIATAInput($renderedElement);

    currentTicketIataItemIndex++;

    $ticketsTidsAddText.html('Add another');

    if (data) {
      const country = getCountryFromCountryCode(data.countryCode);

      const $iataAgentInput = $renderedElement.find('.js-iata-agent');
      const $companyLegalNameInput = $renderedElement.find('.js-company-legal-name');
      const $countryName = $renderedElement.find('.js-country-name');

      $iataAgentInput.val(data.code);
      $companyLegalNameInput.val(data.legalName);
      $countryName.val(country.countryName);

      fieldInputFormat($iataAgentInput);
      fieldInputFormat($companyLegalNameInput);
      fieldInputFormat($countryName);
    }
  }

  function issueTicketOptionHandler(event) {
    const $this = $(this);

    if ($this.hasClass('js-check-own-ticket')) {
      $ticketTidsListContainer.html('');
      pageModel.codeDetails.consolidators = null;
      $ticketsTidsAdd.hide();
    } else {
      $('input[value=use_a_consolidator_or_ticket_center]').attr('checked', true);
      $containerTicketsIssued.removeClass('hidden');
      $containerTicketsIssued.find('.js-remove-item').show();
      $ticketsTidsAdd.trigger('click');
      $ticketsTidsAdd.show();
      resetConsolidatorIndexes();
    }
  }

  function fieldInputFormat($this) {
    if (!$this.val()) {
      return;
    }

    $this.addClass('input-marked-modify');
    $this.parent().find('.js-float-label').addClass('floating-label-modify');
  }

  $ticketsTidsAdd.on('click', renderTicketIssued);

  function checkValidateCheckAgencyType() {
    let boxTicket = 0;

    $('.js-checkbox-handler-agency').each(function (idx, element) {
      const $element = $(element);

      if ($element.is(':checked')) {
        boxTicket = boxTicket + 1;
      }
    });

    if (boxTicket === 0) {
      $('.js-checkbox-error').show();
    } else {
      $('.js-checkbox-error').hide();
    }

    return boxTicket >= 1;
  }

  $(document).on('click', '.js-checkbox-handler-agency', checkValidateCheckAgencyType);

  function getCodeName(codeType, code, isPrimary, legalName) {
    if (codeType === '') {
      return '';
    }

    let hasTextPrimary = isPrimary ? 'Primary': '';

    return  `${hasTextPrimary} ${codeType}: ${code} (${legalName})`;
  }

  $makePrimaryContainer.on('click', function () {
    window.showModalConfirmPrimary();
    let codeIataAgent = $iataAgent.val();
    let typeCode = $iataAgent.parent().find('.js-label-change').text();

    $('.js-code-confirm-primary').text(codeIataAgent);
    $('.js-type-code-primary').text(typeCode);
  });

  function issuedChecked() {
    let checkIssued = false;
    if ($contentDetailsApprove.find('.js-check-own-ticket:checked').length > 0) {
      checkIssued = true;
    }

    return checkIssued;
  }

  function getConsolidators() {
    const countries = window.allCountriesStatesCities.countries;

    let consolidators = [];
    $containerTicketsIssued.find('.js-iata-tids-number').each(function (index, element) {
      const $element = $(element);
      const countryName = $element.find('.js-country-name ').val();
      const country = countries.filter(obj => obj.countryName === countryName);

      consolidators.push({
        consolidatorNumber: index + 1,
        codeType: '',
        code: $element.find('.js-iata-agent').val(),
        legalName: $element.find('.js-company-legal-name').val(),
        countryCode: country.length ? country[0].countryCode : '',
      });
    });

    return consolidators;
  }

  function getDataListAgencyType($container, $listItem) {
    var agencyTypes = [];

    $container.find($listItem).each(function (idx, element) {
      var $element = $(element);
      if (!$element.is(':checked')) {
        return;
      }
      var labelText = $element.parent().text();

      agencyTypes.push(labelText ? labelText.trim() : '');
    });

    return agencyTypes;
  }

  function getSubmitCodeDetailData() {
    pageModel.codeDetails.legalName = $organisationDetail.find('.js-agent-details-legal').val();
    pageModel.codeDetails.stateCode = $organisationDetail.find('.js-agent-details-state').data('value');
    pageModel.codeDetails.cityCode = $organisationDetail.find('.js-agent-details-city').data('value');;

    pageModel.codeDetails.issueOwnTickets = issuedChecked();
    pageModel.codeDetails.consolidators = getConsolidators();
    pageModel.codeDetails.agencyTypes = getAgencyTypes();
    pageModel.listAgencyType = getDataListAgencyType($('.js-agency-type-list'), $('.js-checkbox-handler-agency'));

    return pageModel.codeDetails;
  }

  function getAgencyTypes() {
    let agencyTypes = [];

    $agecyTypeList.find('.js-checkbox-handler-agency').each(function (index, elem) {
      const $elem = $(elem);
      const codeAgencytype = $elem.val();

      if ($elem.is(':checked')) {
        agencyTypes.push({
          agencyTypeCode: codeAgencytype
        });
      }
    });

    return agencyTypes;
  }

  function renderAppendMode(hdbTemplate, $containerList, data) {
    var $htmlCompiled = $(hdbTemplate({ data }));
    $containerList.append($htmlCompiled);
    return $htmlCompiled;
  }

  function getCountryFromCountryCode(code) {
    const countries = window.allCountriesStatesCities.countries;
    if (!countries || !countries.length) {
      return {};
    }
    const country = countries.filter(obj => obj.countryCode === code);
    if (!country.length) {
      return {};
    }

    return country[0];
  }

  function getCityFromCityCode(code) {
    const cities = window.allCountriesStatesCities.cities;
    if (!cities || !cities.length) {
      return {};
    }

    const city = cities.filter(obj => obj.cityCode === code);
    if (!city.length) {
      return {};
    }

    return city[0];
  }

  function getStateFromStateCode(code) {
    const states = window.allCountriesStatesCities.states;
    if (!states || !states.length) {
      return {};
    }

    const state = states.filter(obj => obj.stateCode === code);
    if (!state.length) {
      return {};
    }

    return state[0];
  }

  $inputTicket.on('change', issueTicketOptionHandler);

  $postFormDetailApproved.on('submit', function (event) {
    event.preventDefault();
    $submitCodeDetailApproved.addClass('loading');

    updateCodeDetails().then(function(){
      window.location = `/en/agency-settings.html?updatedCode=${pageModel.codeDetails.code}`;
    }).catch(handleSavedError);
  });

  function handleSavedError(error) {
    console.error(error);
    window.location.href = window.standardUrl('500.html');
  }

  function updateCodeDetails() {
    if (!$postFormDetailApproved.checkValidity()) {
      return;
    }

    const submittedData = getSubmitCodeDetailData();

    return window.sia.APICaller.saveCodeDetails(submittedData).then(function(data) {
      pageModel.unsaved = false;
      setTimeout(() => {
        $submitCodeDetailApproved.removeClass('loading');
      }, 1000);
    });
  }

  $backLink.on('click', function (e) {
    e.preventDefault();

    let $detailPendingReject = $('.js-code-details-pending-rejected-list').children().length;
    $tabActive= $detailPendingReject ? 'pending' : 'approved';

    const url = $backLink.attr('href');

    let $linkBack=`${url}?tab=${$tabActive}`;
    window.location.href = $linkBack;
  });

  $tabDetail.on('click', function (event) {

    if (pageModel.unsaved) {

      window.showModalLeavePage();
      event.preventDefault();
      return false;
    }

    pageModel.unsaved = false;
  });

  window.onbeforeunload = function() {
    const leaveConfirmationPopupShown = $('#modal-leave-page').is(':visible');

    if (pageModel.unsaved && leaveConfirmationPopupShown === false) {
      return 'Are you sure you want to navigate away from this page?\nAll unsaved changes will be lost.';
    }
  };


  $modalPrimaryButtonAction.on('click', function (event) {
    event.preventDefault();
    const $this = $(this);
    let $formModal = $this.closest('#modal-confirm-primary');

    $formModal.modal('toggle');

    if ($formModal.attr('id') === 'modal-confirm-primary') {
      pageModel.codeDetails.isPrimary = true;
    }

    updateCodeDetails();
  });
});
